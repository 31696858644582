:root {
  --color-dark: #27292a;
  --color-darkest: #1f2122;
  --color-light: #fff;
  --color-primary: #ec6090;
  --color-yellow: yellow;

  --color-bg-body: var(--color-darkest);

  --distance-primary: 4%;
  --distance-primary-px: 25px;
  --pading-primary: var(--distance-primary);
  --margin-primary: var(--distance-primary);
  --border-radius-primary: var(--distance-primary-px);

  --font-family-primary: "Poppins", sans-serif;
  --font-weight-normal: 300;
  --font-weight-bold: 700;
}

.default-font {
  font-size: "1em";
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  background: #101010;
  color: #808080;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

/* heading section */
h1,
h2,
h3,
h4 {
  color: #fff;
  font-weight: bold;
}

h1,
h2 {
  font-size: 30px;
}

/* images retina */
img {
  max-width: 100%;
  height: auto;
}

hr {
  padding-top: 40px;
}

/*-------------------*/
/*----- Navbar -----*/
/*-------------------*/
.navbar-default {
  background: #202020;
  border: none;
  border-left: 6px solid #d54ab6;
  /* padding-right: 40px; */
  margin: 0 !important;
  position: relative;
  top: 20px;
  padding: 0px 40px 0px 0px;
}

.navbar-default .navbar-brand {
  font-size: 40px;
  line-height: 40px;
  padding-top: 4px;
}

.navbar-default .navbar-nav li a {
  color: #f2f2f2;
  font-size: 13px;
  font-weight: 400;
  line-height: 40px;
  transition: all 0.4s ease-in;
  text-transform: uppercase;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.navbar-default .navbar-nav .nav-item {
  padding-left: 1%;
  height: 100%;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #d54ab6;
  background-color: #333;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #d54ab6;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  background: #d54ab6;
  border-radius: 0px;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
}
.navbar-default .navbar-toggle .icon-bar {
  background: #fff;
  border-color: transparent;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.active {
  background: #333;
}

.navbar-toggler {
  margin-bottom: 1em;
  margin-top: 1em;
}

.navbar-collapse,
.navbar-toggler {
  margin-left: 1em;
}

/*-------------------*/
/*----- footer -----*/
/*-------------------*/
footer p {
  /* text-align: center; */
  padding: 0.15em 0em;
  color: var(--color-light);
  font-weight: 300;
}

footer p a {
  color: var(--color-light);
}

footer p a:hover {
  color: #d54ab6;
}

.footer-para P {
  color: #808080 !important;
}

.footer-para a {
  padding: 0px 5px;
  text-decoration: none;
  font-style: italic;
  font-weight: 600;
}

.all-rights-sm {
  display: none;
}
.all-rights-lg {
  font-size: 0.75em;
}

.social-logo {
  width: 1rem;
  height: 1em;
  margin-bottom: 1em;
  margin-right: 0.25em;
  cursor: pointer;
}

.social-lnk a {
  text-decoration: none;
  color: #808080 !important;
}
footer p {
  /* text-align: center; */
  /* padding: 10px 0px; */
  color: var(--color-light);
  font-weight: 300;
}

footer p a {
  color: var(--color-light);
}

footer p a:hover {
  color: #d54ab6;
}

.footer-para P {
  color: #808080 !important;
}

.footer-para a {
  padding: 0px 5px;
  text-decoration: none;
  font-style: italic;
  font-weight: 600;
}

.social-lnk a {
  text-decoration: none;
  color: #808080 !important;
}

/*-----------------------*/
/*------- button -------*/
/*----------------------*/
.btn {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0px;
  box-shadow: none;
  color: #fff;
  padding: 12px 18px;
  font-size: 13px;
  font-weight: bold;
  transition: all 0.4s ease-in;
}
.btn:hover {
  background: #d54ab6;
  border-color: transparent;
}

.main-button {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0px;
  box-shadow: none;
  color: #fff;
  padding: 12px 25px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.4s ease-in;
}
.main-button:hover {
  background: #d54ab6;
  border-color: transparent;
}

.see-more-button a {
  font-size: 0.6em;
  color: #fff;
  padding: 12px 30px 10px 0px;
  text-decoration: none;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
}

.see-more-button a:hover {
  text-shadow: 0.75px 0.75px #040404;
}

.see-more-button a span {
  padding-left: 30%;
}

/*-------------------*/
/*----- section ----*/
/*-------------------*/
.margin-sec {
  margin-top: 3em;
  margin-bottom: 3em;
  padding-top: 2em;
}

.section-header {
  margin-bottom: calc(var(--margin-primary) * 0.5);
}

.section-header .first-title {
  font-size: 2em;
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

.section-header .second-title {
  font-size: 2em;
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  padding-left: calc(var(--pading-primary) / 4);
}

.section-wrapper {
  background-color: #191919;
}
/*-------------------*/
/*------- Home ------*/
/*-------------------*/
.main {
  background: url("./assets/images/pexels.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.main h1 {
  display: inline-block;
  letter-spacing: 1px;
  line-height: 70px;
  margin-top: -20px;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 4.5px 4.5px 3px #333;
  font-size: 2.5em;
  font-weight: bolder;
}

.main h2 {
  color: #fff;
  text-shadow: 2.5px 2.5px #333;
  font-size: 1.25em;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bolder;
  display: inline-block;
  margin-top: -20px;
  margin-bottom: 3px;
}

.main-container {
  padding: calc(var(--pading-primary) / 2) var(--pading-primary)
    var(--pading-primary);
  /* background-color: var(--color-dark); */
  border-radius: var(--border-radius-primary);
  color: var(--color-light);
}

/* divider section */
.divider {
  color: #fff;
  text-align: center;
}

.divider .divider-wrapper {
  padding: 2em 1em 1em 1em;
  margin: 0 0.5em;
  position: relative;
  bottom: 40px;
  transition: all 0.4s ease-in;
  border-left: solid 5px #101010;
  border-right: solid 5px #101010;
  max-width: 17em;
}

.divider .divider-wrapper:hover {
  bottom: 3em;
  cursor: pointer;
}

.divider li {
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 32px;
  width: 80px;
  height: 80px;
  line-height: 76px;
  text-transform: capitalize;
  list-style-type: none;
  display: inline-block;
}

.divider h2 {
  padding-top: 10px;
}

.divider p {
  padding: 0% 7%;
  text-align: justify;
}

.devider-detl {
  hyphens: auto;
}

.divider p span {
  display: inline-flex;
}

.divider-card {
  flex-wrap: wrap;
}

.intro-sec {
  margin-top: 50px;
}

.intro-sec h2 {
  text-align: left;
  padding: 20px 15px 10px;
}

.intro-sec h2 span {
  font-size: 25px;
  font-weight: 400;
}

.intro-sec p,
.intro-summary-sec p {
  font-size: 1em;
  padding: 0px 10px;
  text-align: justify;
}

.intro-summary-sec h2 {
  padding: 20px 15px;
}

/*-------------------*/
/*------ About -----*/
/*-------------------*/
.about-image img {
  padding: 0;
  margin: 0;
}
.about-des {
  padding: 20px 20px 20px 20px;
}

.about-des h2 {
  padding-top: 10px;
}

.about-des p {
  padding-top: 3px;
  text-align: justify;
}

.about-btn {
  margin: 30px 0px;
}

.about-header {
  background: url("./assets/images/narrow-about-cover-pages.png") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 5em;
  padding-top: 5em;
  padding-bottom: 5em;
}

.progress {
  border-radius: 0px;
  height: 4px;
  width: 100%;
}

.web-lang {
  padding-bottom: 30px;
}

.lang-det {
  padding-right: 5%;
  padding-left: 5%;
  text-align: justify;
}

.t-logo-lg {
  color: #fff;
  text-align: center;
  padding: 0em 3em 1em 3em;
  display: block;
}

.t-logo-sm {
  display: none;
}

.tech-logos {
  border: 3px solid #d54ab6;
  color: #333;
  background-color: #fff;
  border-radius: 50%;
  font-size: 32px;
  width: 90px;
  height: 90px;
  line-height: 76px;
  text-transform: capitalize;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-logo {
  padding: 20px 0px 20px 0px;
}

.logos {
  width: 50px;
  height: 50px;
}

.wrapp-logos {
  border: 2px solid #000;
  border-radius: 50%;
  width: 85px;
  height: 85px;
}

.one-graph .graph-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.one-graph .graph-title span {
  font-size: 16px;
  font-weight: 600;
}

.one-graph {
  margin-bottom: 20px;
}

.certif-group {
  width: 95%;
}

.certif-card {
  width: 30%;
}

.certif-img {
  opacity: 0.8;
  cursor: pointer;
}

.certif-h4,
.certif-h6,
.certif-p {
  text-align: center;
  padding-top: 5px;
}

.certif-h4 {
  margin-top: 10px;
}

.certif-h6 {
  color: #d54ab6;
  font-size: 16px;
  font-weight: 700;
}

.certif-p {
  font-size: 14px;
  font-weight: 400;
}

.contact-info p {
  text-align: justify;
  padding-right: 0% 3%;
}

/*-------------------*/
/*----- Services ----*/
/*-------------------*/
.serv-cont {
  margin: 20px 0px 20px;
}

.serv-main-cont {
  width: 90%;
  margin: auto;
  padding: 2em 1em;
}

.serv-card {
  border-style: solid;
  border-color: #1f2122;
  box-shadow: 0.2em 0.2em 0.2em #242323;
}

.serv-image {
  padding: 1em;
}

.serv-des h2,
.serv-des-rev h2 {
  text-align: left !important;
  padding-top: 1em;
}

.serv-des p,
.serv-des-rev p {
  text-align: justify;
}

.services-header {
  background: url("./assets/images/narrow-services-cover-pages.png") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 5em;
  padding-top: 5em;
  padding-bottom: 5em;
}

/*-------------------*/
/*----- Project -----*/
/*-------------------*/

.proj-wrapper {
  border: 2px solid #303030;
  padding: 20px;
  margin-bottom: 1em;
}

.proj-sec {
  padding: 1em;
}

.proj-card {
  width: 30%;
}

.motion-div-sm {
  display: none;
}

.portf-img {
  transition: all 0.4s ease-in;
  opacity: 0.8;
  padding-left: 0px;
  padding-right: 0px;
}

.portf-img img {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  height: auto;
}

.img-border {
  border: solid 2px #d54ab6;
}

.proj-img {
  opacity: 0.8;
  position: relative;
  border: solid 2px #d54ab6;
}

.proj-img:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: rgb(245, 243, 243);
  z-index: -1;
  border: solid 2px #d54ab6;
}

.proj-h4,
.proj-h6 {
  text-align: center;
  padding-top: 5px;
}

.proj-h4 {
  margin-top: 10px;
}

.proj-h6 {
  color: #d54ab6;
  font-size: 18px;
  font-weight: 700;
}

.proj-p {
  text-align: justify;
  padding-top: 0.25em;
  padding-bottom: 1.5em;
}

.proj-page-img {
  cursor: pointer;
}

.project-header {
  background: url("./assets/images/narrow-projects-cover-pages.png") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 5em;
  padding-top: 5em;
  padding-bottom: 5em;
}

/*-------------------*/
/*----- Contact -----*/
/*-------------------*/
.email {
  margin-top: 10px;
  padding: 8px 12px;
}

.mail-form {
  margin: 3em 0 0.5em 0;
}

.mail-form h5 {
  margin-bottom: 0.75em;
  font-weight: 700;
  color: #fff;
}

.btn-email {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0px;
  box-shadow: none;
  color: #fff;
  margin-top: 10px !important;
  padding: 0.5em 1.2em !important;
  font-size: 0.75em;
  font-weight: bold;
  transition: all 0.4s ease-in;
}

.btn-email:hover {
  background: #d54ab6;
  border-color: transparent;
}

.mail-form h6 {
  margin: 0px 5px 15px 0px;
  font-weight: 600;
}

.mail-form h6 span {
  color: #fff;
  font-size: 1.1rem;
  padding-right: 10px;
}

.form-lg {
  display: block;
}

.form-sm {
  display: none;
}

.contact-header {
  background: url("./assets/images/narrow-contact-cover-pages.png") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 5em;
  padding-top: 5em;
  padding-bottom: 5em;
}

.contact-wrapper {
  width: 100%;
  background-color: #191919;
  margin-top: 70px;
  margin-bottom: 30px;
  padding: 1em 0.5em;
}

.contact-footer-lg {
  display: block;
}

.contact-footer-sm {
  display: none;
}

.map-wrapper {
  margin: -10px 0px 30px;
  padding: 10px;
  height: 350px;
  border: solid;
  border-color: #d54ab6;
  border-width: 3px;
}

.map-el {
  border: solid;
  border-color: #d54ab6;
  border-width: 5px;
}

.map-container {
  padding-right: 0px !important;
  margin: 1em;
}

.map-box h2 {
  padding-left: 0.5em;
}

.map-box h5 {
  margin-bottom: 0.75em;
  font-weight: 700;
  color: #fff;
}

.map-title-lg {
  display: block;
  margin-top: 4em;
}

.map-title-sm {
  display: none;
}
/*-------------------*/
/*---- Cover Txt ----*/
/*-------------------*/

.about-cover-txt h1 {
  display: inline-block;
  letter-spacing: 1px;
  line-height: 70px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 4.5px 4.5px 3px #333;
  font-size: 4em;
  font-weight: bolder;
}

.about-cover-txt h2 {
  color: #fff;
  text-shadow: 2.5px 2.5px #333;
  font-size: 2, 5em;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bolder;
  display: inline-block;
  margin-bottom: 3px;
}

.cover-txt-sm {
  display: none;
}

.service-cover-txt h1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 70px;
  color: #fff;
  text-shadow: 2px 2px 2px #333;
  font-size: 3em;
  font-weight: bolder;
}

.service-cover-txt h3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  letter-spacing: 4px;
  line-height: 30px;
  color: #555;
  text-shadow: 2.5px 2.5px #fff;
  font-size: 1.25em;
  font-weight: bolder;
  padding-left: 2%;
}

.reri-cover-txt h1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  letter-spacing: 4px;
  line-height: 70px;
  color: #fff;
  text-shadow: 2px 2px 2px #333;
  font-size: 3em;
  font-weight: bolder;
}

.reri-cover-txt h3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  letter-spacing: 2.5px;
  line-height: 30px;
  margin-top: 25px;
  color: #555;
  text-shadow: 2.5px 2.5px #fff;
  font-size: 1.25em;
  font-weight: bolder;
  padding-left: 2%;
}

@media (max-width: 575px) {
  .container {
    width: 95%;
    text-align: center;
  }

  .contact-header {
    background: url("./assets/images/contact-wall-small.png") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .project-header {
    background: url("./assets/images/projects-wall-small.png") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .services-header {
    background: url("./assets/images/narrow-blank-cover-pages.png") no-repeat;
  }
  .divider .divider-wrapper {
    margin-bottom: 20px;
  }
  .intro-sec h2,
  .intro-summary-sec h2,
  .lang-det h2 {
    font-size: 25px;
    text-align: center;
  }

  .margin-sec {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 0.5em;
  }

  .motion-div {
    width: 30%;
    margin: "0.1em";
    margin-bottom: 0.5em;
  }

  .intro-sec h2 {
    text-align: center;
    padding: 20px 15px 10px;
  }

  .certif-card {
    width: 95%;
    margin-bottom: "0.9em";
  }

  .serv-card {
    padding: 0.5em 1em;
  }

  .serv-image {
    padding: 0.5em;
  }

  .proj-sec {
    padding: 0;
    margin: 0 !important ;
  }

  .proj-card {
    width: 80%;
  }

  .mail-form h5 {
    text-align: "center";
  }

  .form-lg {
    display: none;
  }

  .form-sm {
    display: block;
  }
  .map-title-lg {
    display: none;
  }

  .map-title-sm {
    display: block;
    margin-top: 3em;
  }
  .t-logo-lg {
    display: none;
  }

  .t-logo-sm {
    color: #fff;
    text-align: center;
    display: block;
  }
  .contact-footer-lg {
    display: none;
  }

  .contact-footer-sm {
    display: block;
  }

  .short-form-sm {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .all-rights-sm {
    display: block;
    font-size: 0.75em;
  }
  .all-rights-lg {
    display: none;
  }
  .cover-txt-lg {
    display: none;
  }
  .cover-txt-sm {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    width: 85%;
    text-align: center;
  }

  .services-header {
    background: url("./assets/images/narrow-blank-cover-pages.png") no-repeat;
  }

  .divider .divider-wrapper {
    margin-bottom: 1em;
    max-width: 90%;
  }

  .intro-sec h2,
  .intro-summary-sec h2,
  .lang-det h2 {
    font-size: 25px;
    text-align: center;
  }

  .margin-sec {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 0.5em;
  }

  .motion-div-lg {
    display: none;
  }

  .motion-div-sm {
    display: block;
  }

  .certif-card {
    width: 95%;
    margin-bottom: "0.9em";
  }

  .serv-card {
    padding: 0.5em 1em;
  }

  .serv-image {
    padding: 0.5em;
  }

  .proj-sec {
    padding: 0;
  }

  .proj-card {
    width: 90%;
  }

  .proj-page-img {
    padding: 0 1em;
    max-width: 90%;
  }

  .contact-header,
  .project-header {
    background: url("./assets/images/narrow-blank-cover-pages.png") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .mail-form h5 {
    text-align: "center";
  }

  .form-lg {
    display: none;
  }

  .form-sm {
    display: block;
  }

  .contact-footer-lg {
    display: none;
  }

  .contact-footer-sm {
    display: block;
  }
  .short-form-sm {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .all-rights-sm {
    display: block;
    font-size: 0.75em;
  }
  .all-rights-lg {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 90%;
    text-align: center;
  }

  .service-cover-txt h1,
  .service-cover-txt h3 {
    text-align: center;
  }

  .services-header {
    background: url("./assets/images/narrow-serviceswm-cover-pages.png")
      no-repeat;
    background-size: cover;
    background-position: center;
  }

  .project-header {
    background: url("./assets/images/narrow-projectswm-cover-pages.png")
      no-repeat;
    background-size: cover;
    background-position: center;
  }

  .contact-header {
    background: url("./assets/images/narrow-contactwm2-cover-pages.png")
      no-repeat;
    background-size: cover;
    background-position: center;
  }

  .divider .divider-wrapper {
    padding: 1em 0, 5em;
    margin: 0.75em;
    max-width: 90%;
  }

  .divider-card {
    flex-wrap: nowrap;
  }
  .about-image {
    align-items: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .container {
    width: 80%;
  }

  .service-cover-txt h1,
  .service-cover-txt h3 {
    text-align: center;
  }

  .services-header {
    background: url("./assets/images/narrow-service-md-cover-pages.png")
      no-repeat;
    background-size: cover;
    background-position: center;
  }

  .project-header {
    background: url("./assets/images/narrow-project-md-cover-pages.png")
      no-repeat;
    background-size: cover;
    background-position: center;
  }

  .contact-header {
    background: url("./assets/images/narrow-contact-md-cover-pages.png")
      no-repeat;
    background-size: cover;
    background-position: center;
  }

  .divider .divider-wrapper {
    max-width: 30%;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .container {
    width: 85%;
  }
}
